.StudCaard{
    border-radius: 40px;
    border-color: white;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    /* padding: 14px 80px 18px 36px; */
    padding: 10px 40px 14px 32px;
    cursor: pointer;
    text-align: center;
    /* margin-top: 50px; */
}

.StudCaard:hover{
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.Studbutn{
     /* border-radius: 20px;
    border-color: #2FBFE3;
    color: #2FBFE3;
     */
     /* float: left; */

    /* position: relative; 
    margin: auto;
    float: none;
    width: 30%;  */

    border-radius: 80px; 
    border-color: #2FBFE3; 
    color: #2FBFE3; 
    float: left;
    position: relative;
    margin: auto;
    float: none;
    /* width: 70%; */
}

.Studbutn:hover{
    background-color: #2FBFE3;
    border-color: #2FBFE3;
}

.brand {
    color:#696969;
    text-align: center;
}
