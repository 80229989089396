.foooter{
    background-color: #CBCDCD;
    padding: 10px 14px;
    /* padding: 12px 14px; */
}

.fa {
    padding: 20px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
  }
  
  .fa:hover {
      opacity: 0.7;
  }
  
  .fa-facebook {
    background: #3B5998;
    color: white;
  }

  .fa-google {
    background: #dd4b39;
    color: white;
  }
  
  .fa-linkedin {
    background: #007bb5;
    color: white;
  }

  .fa-instagram {
    background: #125688;
    color: white;
  }

  .social-media{
    float: right;
  }

  .contact-us{
    float: left;
  }

  .foo{
    background-color: #A8ABAB;
    text-align: center;
    font-weight: 600;
    padding: 12px 14px;
  }