.opButton{
  width: 100%; 
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #234668;
  cursor: pointer;
  /* width: max-content; */
}

.app {
  background-color: #252d4a;
  width: 100%;
  min-height: 300px;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: space-evenly;
  color: #ffffff;
  margin-top: 10%;
  margin-bottom: 50%;
}

.score-section {
  display: flex;
  font-size: 24px;
  align-items: center;
}

.question-section {
  width: 100%;
  position: relative;
}

.question-count {
  margin-bottom: 20px;
}

.question-count span {
  font-size: 28px;
}

.question-text {
  margin-bottom: 12px;
}

.answer-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

Button:hover {
  background-color: #555e7d;
}

button:focus {
  outline: none;
}

button svg {
  margin-right: 5px;
}




  
  
  