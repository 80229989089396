@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

.font{
    font-weight: 600;
    font-style: initial;
    font-family: "Mulish", sans-serif;
}

.font-h1{
    font-size: 30px;
    text-align: center;
    font-weight: bolder;
}

.brand {
    color:#696969;
    text-align: center;
}

.butn{
    border-radius: 80px; 
    border-color: #2FBFE3; 
    color: #2FBFE3; 
    float: left;
    position: relative;
    margin: auto;
    float: none;
}

.butn:hover{
    background-color: #2FBFE3;
    border-color: #2FBFE3;
}

.Caard{
    border-radius: 40px;
    border-color: white;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    /* padding: 14px 80px 18px 36px; */
    /* padding: 10px 40px 14px 32px; */
    padding: 10px 14px 10px 20px;
    cursor: pointer;
    text-align: center;
    /* margin-top: 50px; */
}

.Caard:hover{
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

/* .foooter{
    background-color: #CBCDCD;
    padding: 10px 14px;
} */

/* .fa {
    padding: 20px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
  }
  
  .fa:hover {
      opacity: 0.7;
  }
  
  .fa-facebook {
    background: #3B5998;
    color: white;
  }

  .fa-google {
    background: #dd4b39;
    color: white;
  }
  
  .fa-linkedin {
    background: #007bb5;
    color: white;
  }

  .fa-instagram {
    background: #125688;
    color: white;
  }

  .social-media{
    float: right;
  }

  .contact-us{
    float: left;
  }

  .foo{
    background-color: #A8ABAB;
    text-align: center;
    font-weight: 600;
    padding: 12px 14px;
  } */