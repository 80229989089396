.Aboutfont{
    font-weight: 600;
    font-style: initial;
    font-family: "Mulish", sans-serif;
}

.Aboutfont-h1{
    font-size: 20px;
    text-align: center;
    /* font-weight: bolder; */
}