@import url(https://fonts.googleapis.com/css2?family=Mulish&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish&display=swap);
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mulish", sans-serif;
}

.font{
  font-style: normal;
}

/* .navbar{
  background-color: white;
} */

.navbar a{
  font-size: 18px;
  text-transform: capitalize;
}

.navbar a:hover {
  color: #3498db;
}

.menu_active {
  /* font-weight: bold; */
  border-bottom: 1px solid #565387;
}

@media (max-width: 991px) {
.menu_active{
  font-weight: bold;
  border-bottom: none;
  }
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 10px;
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.font{
    font-weight: 600;
    font-style: normal;
    font-style: initial;
    font-family: "Mulish", sans-serif;
}

.font-h1{
    font-size: 30px;
    text-align: center;
    font-weight: bolder;
}

.brand {
    color:#696969;
    text-align: center;
}

.butn{
    border-radius: 80px; 
    border-color: #2FBFE3; 
    color: #2FBFE3; 
    float: left;
    position: relative;
    margin: auto;
    float: none;
}

.butn:hover{
    background-color: #2FBFE3;
    border-color: #2FBFE3;
}

.Caard{
    border-radius: 40px;
    border-color: white;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    /* padding: 14px 80px 18px 36px; */
    /* padding: 10px 40px 14px 32px; */
    padding: 10px 14px 10px 20px;
    cursor: pointer;
    text-align: center;
    /* margin-top: 50px; */
}

.Caard:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

/* .foooter{
    background-color: #CBCDCD;
    padding: 10px 14px;
} */

/* .fa {
    padding: 20px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
  }
  
  .fa:hover {
      opacity: 0.7;
  }
  
  .fa-facebook {
    background: #3B5998;
    color: white;
  }

  .fa-google {
    background: #dd4b39;
    color: white;
  }
  
  .fa-linkedin {
    background: #007bb5;
    color: white;
  }

  .fa-instagram {
    background: #125688;
    color: white;
  }

  .social-media{
    float: right;
  }

  .contact-us{
    float: left;
  }

  .foo{
    background-color: #A8ABAB;
    text-align: center;
    font-weight: 600;
    padding: 12px 14px;
  } */
 .shape{
    border-radius: 50%; 
    background-color: white;
    border: none;
} 

.img-set{
    width: 90%;
    height: auto;
}
.text{
    font-weight: bold;
    
}
.foooter{
    background-color: #CBCDCD;
    padding: 10px 14px;
    /* padding: 12px 14px; */
}

.fa {
    padding: 20px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
  }
  
  .fa:hover {
      opacity: 0.7;
  }
  
  .fa-facebook {
    background: #3B5998;
    color: white;
  }

  .fa-google {
    background: #dd4b39;
    color: white;
  }
  
  .fa-linkedin {
    background: #007bb5;
    color: white;
  }

  .fa-instagram {
    background: #125688;
    color: white;
  }

  .social-media{
    float: right;
  }

  .contact-us{
    float: left;
  }

  .foo{
    background-color: #A8ABAB;
    text-align: center;
    font-weight: 600;
    padding: 12px 14px;
  }
/* .Inputs{
    background-color: white;
    height: auto;
    width: 30%;
    padding: 20px;
    padding-right: 30px;
    margin: auto;
} */
.Res{
    background-color: white;
    height: auto;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
    padding: 30px;
    margin: auto;

}
.Valid:after{
  
    content:" *";
    color: red;
 
}
.Style{
      /* margin-top: 50%; */
    /* margin-bottom: 50px; */
    
   font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 15px;
    color: black;
    border-radius: 40px;
    cursor: pointer;
    text-align: center;
    /* align-content: center; */
}

.margin-top{
    margin-top: 10%;
}
.Aboutfont{
    font-weight: 600;
    font-style: normal;
    font-style: initial;
    font-family: "Mulish", sans-serif;
}

.Aboutfont-h1{
    font-size: 20px;
    text-align: center;
    /* font-weight: bolder; */
}
 .form{
  border-radius: 5px;
  background-color: white;
  padding: 20px;
  width: 70%;
  margin-left: 15%;
  margin-top: 5%;
} 
/* .Inputs{
    background-color: white;
    height: auto;
    width: 40%;
    padding: 25px;
    margin: auto;
}  */
.Ressignup{
  background-color: white;
  height: auto;
 width: 50%;
 /* width: fit-content; */
  padding: 30px;
  margin: auto;
}

.Stylesignup{
      margin-top: 40px;
    /* margin-bottom: 50px;*/
   font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 15px;
    
    color: black;
    border-radius: 40px;
    text-align: center;
}
.Valid:after{
  
    content:" *";
    color: red;
 
}
.Password{
  
  float: left;
  color: gray;

}


/* .d-flex {
    display: flex!important;
    height: 100vh;
  }
  
.center {
    margin: 0 auto;
    align-self: center;
  } */

 .button{
    background-color: white;
    color: black;
    font-size: 20px;
} 

.nav-color{
  margin-top: 20px;
}
.text{
    font-weight: bold;
    
}
.StudCaard{
    border-radius: 40px;
    border-color: white;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    /* padding: 14px 80px 18px 36px; */
    padding: 10px 40px 14px 32px;
    cursor: pointer;
    text-align: center;
    /* margin-top: 50px; */
}

.StudCaard:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.Studbutn{
     /* border-radius: 20px;
    border-color: #2FBFE3;
    color: #2FBFE3;
     */
     /* float: left; */

    /* position: relative; 
    margin: auto;
    float: none;
    width: 30%;  */

    border-radius: 80px; 
    border-color: #2FBFE3; 
    color: #2FBFE3; 
    float: left;
    position: relative;
    margin: auto;
    float: none;
    /* width: 70%; */
}

.Studbutn:hover{
    background-color: #2FBFE3;
    border-color: #2FBFE3;
}

.brand {
    color:#696969;
    text-align: center;
}

.TenQuizCaaard{
    border-radius: 40px;
    border-color: #AEF0F9;
    background-color:  #2FBFE3;
    background-color: #AEF0F9;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    /* padding: 14px 80px 18px 36px; */
     /* padding: 10px 40px 14px 32px; */
    cursor: pointer;
    text-align: center;
    margin-top: 50px;
 }

.TenQuizCaaard:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    background-color: white;
    border-color: white;
}

.TenQuizbuttn{
    border-radius: 20px;
    border-color: #AEF0F9;
    background-color: white;
    color: black;
    float: left;
    position: relative;
    margin: auto;
    float: none;
} */

.TenQuizbuttn:hover{
    background-color: #2FBFE3;
    border-color: #2FBFE3;
}

.TenQuizbraand {
    color: black;
    text-align: center;
} */

.TenQuizbraand:hover{
    color: black;
}

.opButton{
  width: 100%; 
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: -webkit-flex;
  display: flex;
  padding: 5px;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  border: 5px solid #234668;
  cursor: pointer;
  /* width: max-content; */
}

.app {
  background-color: #252d4a;
  width: 100%;
  min-height: 300px;
  height: -webkit-min-content;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  color: #ffffff;
  margin-top: 10%;
  margin-bottom: 50%;
}

.score-section {
  display: -webkit-flex;
  display: flex;
  font-size: 24px;
  -webkit-align-items: center;
          align-items: center;
}

.question-section {
  width: 100%;
  position: relative;
}

.question-count {
  margin-bottom: 20px;
}

.question-count span {
  font-size: 28px;
}

.question-text {
  margin-bottom: 12px;
}

.answer-section {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

Button:hover {
  background-color: #555e7d;
}

button:focus {
  outline: none;
}

button svg {
  margin-right: 5px;
}




  
  
  
