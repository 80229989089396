@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mulish", sans-serif;
}

.font{
  font-style: normal;
}

/* .navbar{
  background-color: white;
} */

.navbar a{
  font-size: 18px;
  text-transform: capitalize;
}

.navbar a:hover {
  color: #3498db;
}

.menu_active {
  /* font-weight: bold; */
  border-bottom: 1px solid #565387;
}

@media (max-width: 991px) {
.menu_active{
  font-weight: bold;
  border-bottom: none;
  }
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
