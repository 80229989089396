/* .Inputs{
    background-color: white;
    height: auto;
    width: 30%;
    padding: 20px;
    padding-right: 30px;
    margin: auto;
} */
.Res{
    background-color: white;
    height: auto;
   width: fit-content;
    padding: 30px;
    margin: auto;

}
.Valid:after{
  
    content:" *";
    color: red;
 
}
.Style{
      /* margin-top: 50%; */
    /* margin-bottom: 50px; */
    
   font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 15px;
    color: black;
    border-radius: 40px;
    cursor: pointer;
    text-align: center;
    /* align-content: center; */
}

.margin-top{
    margin-top: 10%;
}