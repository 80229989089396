 .form{
  border-radius: 5px;
  background-color: white;
  padding: 20px;
  width: 70%;
  margin-left: 15%;
  margin-top: 5%;
} 
/* .Inputs{
    background-color: white;
    height: auto;
    width: 40%;
    padding: 25px;
    margin: auto;
}  */
.Ressignup{
  background-color: white;
  height: auto;
 width: 50%;
 /* width: fit-content; */
  padding: 30px;
  margin: auto;
}

.Stylesignup{
      margin-top: 40px;
    /* margin-bottom: 50px;*/
   font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 15px;
    
    color: black;
    border-radius: 40px;
    text-align: center;
}
.Valid:after{
  
    content:" *";
    color: red;
 
}
.Password{
  
  float: left;
  color: gray;

}


/* .d-flex {
    display: flex!important;
    height: 100vh;
  }
  
.center {
    margin: 0 auto;
    align-self: center;
  } */

 .button{
    background-color: white;
    color: black;
    font-size: 20px;
} 

.nav-color{
  margin-top: 20px;
}